import React, { RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, createStyles, withStyles, Typography, Table, TableCell, TableHead, TableRow, TableBody, Paper, Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { defaultColors, defaultStyles, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import MUIRichTextEditor from 'mui-rte'
import moment from 'moment'
import { convertToRaw, convertFromRaw } from 'draft-js'
import { Button } from '../customComponents'
import { useContainer } from 'unstated-next'
import { CommentContainer } from './commentStore'
import { snackbars } from "../../../infrastructure/snackbars"

let dialogRef: RefObject<{ open: (associationKey: string, readonly?: boolean) => void }> | null = null

function _CommentButtonDialog({ classes }) {
    let [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    let [editorContent, setEditorContent] = useState<Draft.EditorState | null>(null)
    let [commentItem, setCommentItem] = useState<CommentItem | null>(null)
    let [associationKey, setAssociationKey] = useState<string | null>(null)
    let [showHistory, setShowHistory] = useState<boolean>(false)
    let [history, setHistory] = useState<CommentHistory[]>([])
    let [isReadOnly, setIsReadOnly] = useState<boolean>(false)

    let store = useContainer(CommentContainer)
    dialogRef = useRef<{ open: (associationKey: string, readonly?: boolean) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (associationKey: string, readonly?: boolean) => {
            setAssociationKey(associationKey)
            setIsDialogOpened(true)
            setIsReadOnly(!!readonly)
        }
    }))

    useEffect(() => {
        if (!associationKey) return
        let fetchComment = async () => await loadComment()
        fetchComment()
    }, [associationKey])

    let loadComment = async () => {
        let comment = await api.get<CommentItem | null>(`comment?associationKey=${associationKey}`)
        setComment(comment)
    }

    let setComment = (comment?: CommentItem | null) => {
        if (!comment) {
            setCommentItem(null)
            return
        }

        let commentContent = store.convertContentTextToJSONString(comment.content)
        setCommentItem({ ...comment, content: commentContent })
    }

    let saveComment = async () => {
        if (isUnmodifiedComment()) {
            snackbars.info(t('comment.unModifiedComment'))
            return
        }

        await api.post('comment', {
            associationKey: associationKey,
            content: JSON.stringify(convertToRaw(editorContent!.getCurrentContent()))
        })

        store.addExistence(associationKey!)
        closeDialog()
    }

    let closeDialog = () => {
        setIsDialogOpened(false)
        setShowHistory(false)
        setAssociationKey(null)
        setCommentItem(null)
        setHistory([])
        setEditorContent(null)
    }

    let isUnmodifiedComment = () => {
        if (!editorContent) return true
        if (!commentItem) return false

        let newComment = editorContent.getCurrentContent().getPlainText()
        let oldComment = getPlainTextContent(commentItem!.content)
        return oldComment === newComment
    }

    let getPlainTextContent = (content: string) => convertFromRaw(JSON.parse(content)).getPlainText()

    let loadHistory = async () => {
        let result = await api.get<CommentHistory[]>(`comment/history?associationKey=${associationKey}`)
        setHistory(refineCommentHistory(result))
    }

    let expandAccordion = async () => {
        setShowHistory(!showHistory)
        await loadHistory()
    }

    let refreshCommentAndHistory = async () => {
        await loadComment()
        await loadHistory()
        setShowHistory(true)
    }

    let refineCommentHistory = (commentHistory: CommentHistory[]): CommentHistory[] =>
        commentHistory.map(x => {
            let date = moment(x.date).format('MM/DD/YYYY')
            let content = getPlainTextContent(x.content)

            return { ...x, date: date, content: content }
        })

    return (
        <Dialog
            open={isDialogOpened}
            onClose={closeDialog}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle className={classes.dialogContainer}>
                <div className={classes.popupHeader}>
                    {t('comment.dialogTitle')}
                    {commentItem?.content
                        ? <Typography className={classes.commentInfo}>
                            {t('comment.infoLabel', {
                                user: commentItem.user,
                                date: moment(commentItem?.createdAt).format('MM/DD/YYYY')
                            })}
                        </Typography>
                        : null}
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContentContainer} dividers={true}>
                <Paper className={classes.marginTop}>
                    <div className={classes.contentContainer}>
                        <MUIRichTextEditor
                            defaultValue={commentItem?.content}
                            onChange={e => setEditorContent(e)}
                            inheritFontSize={true}
                            classes={{}}
                            label={isReadOnly ? '' : t('comment.placeHolder')}
                            controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'highlight', 'undo', 'redo', 'link', 'numberList', 'bulletList', 'quote', 'code', 'clear', 'media']}
                            readOnly={isReadOnly} />
                    </div>
                </Paper>
                <div className={classes.marginTop}>
                    <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        expanded={showHistory}
                        className={classes.accordion}>
                        <AccordionSummary
                            className={classes.accordionSummary}
                            expandIcon={<Icons.ExpandMore onClick={() => expandAccordion()} />}>
                            <div className={classes.accordionSummaryContent}>
                                <Typography
                                    className={classes.paperTitle}
                                    variant='overline'
                                    display='block'>
                                    {t('comment.history.title')}
                                </Typography>
                                <Tooltip title={<Typography variant='body1'>{t('comment.history.refresh')}</Typography>} placement='top'>
                                    <Icons.CachedOutlined className={classes.refreshIcon} onClick={refreshCommentAndHistory} />
                                </Tooltip>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetail}>
                            <Table stickyHeader aria-label='sticky table' className={classes.table}>
                                <TableHead className={classes.thead}>
                                    <TableRow className={classes.row} key={"header"}>
                                        <TableCell className={classes.historyCellMedium}>{t('comment.history.userName')}</TableCell>
                                        <TableCell className={classes.historyCellSmall}>{t('comment.history.date')}</TableCell>
                                        <TableCell className={classes.historyCell}>{t('comment.history.comment')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {history.map((x, index) => {
                                        return <TableRow className={classes.row} key={index}>
                                            <TableCell className={classes.historyCellMedium}>{x.userName}</TableCell>
                                            <TableCell className={classes.historyCellSmall}>{x.date}</TableCell>
                                            <TableCell className={classes.historyCell}>{x.content}</TableCell>
                                        </TableRow>
                                    })}

                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.closeButton}
                    label={t('comment.closeButton')}
                    onClick={closeDialog}
                    color='primary' />
                {!isReadOnly &&
                    <Button className={classes.saveButton}
                        label={t('comment.saveButton')}
                        onClick={saveComment}
                        color='primary' />
                }
            </DialogActions>
        </Dialog>
    )
}

let styles = _ =>
    createStyles({
        saveButton: { ...defaultStyles.dialogPrimaryButton },
        closeButton: { ...defaultStyles.dialogCloseButton },
        contentContainer: {
            minHeight: '15em',
            width: '50em',
            padding: '5px 15px'
        },
        commentInfo: {
            fontSize: '0.7em',
            fontStyle: 'italic',
            color: defaultColors.grey.light.color
        },
        dialogContainer: {
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        dialogContentContainer: {
            paddingTop: '0px',
            paddingBottom: '10px'
        },
        popupHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        historyCell: {
            padding: '5px',
            wordBreak: 'break-word'
        },
        historyCellSmall: {
            padding: '5px',
            width: '120px',
            alignContent: 'baseline'
        },
        historyCellMedium: {
            padding: '5px',
            width: '180px',
            alignContent: 'baseline'
        },
        marginTop: { marginTop: '15px' },
        accordion: { margin: '0 !important' },
        accordionSummary: {
            margin: '0 !important',
            minHeight: '48px !important',
            '& .MuiAccordionSummary-content': { margin: '0 !important' },
        },
        accordionSummaryContent: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
        },
        refreshIcon: { color: 'rgba(0,0,0,.5)' },
        paperTitle: { color: defaultColors.red.main.color },
        table: { width: '50em' }
    })

export let CommentButtonDialog = withStyles(styles, muiOptions)(_CommentButtonDialog)
export let commentButtonDialog = { open: (associationKey: string, readonly?: boolean) => dialogRef?.current?.open(associationKey, readonly) }

type CommentItem = {
    associationKey: string
    content: string
    user: string
    createdAt: Date
}

type CommentHistory = {
    userName: string
    date: string
    content: string
}