import guid, { Guid } from './../../infrastructure/guid'
import moment from 'moment'
import { t } from '../../infrastructure/i18nextHelper'
import { hasFeature } from '../../infrastructure/feature'

type StringDate = string

export let PurchaseType = 'Purchase'
export let UntriggeredType = 'Untriggered'
export let MktSaleType = 'MktSale'

export type DealLabelId = {
    id: Guid
    reference: string | null
    validFrom: StringDate | null
    validTo: StringDate | null
    dutyStatus: string | null
    site: string | null
}

export type MovementForm = {
    id: Guid
    stockInputDate: StringDate | null
    stockOutputDate: StringDate | null
    requestedDate: StringDate | null
    volume: number | null
    nominatedVolume: number | null
    reference: string | null
    originDutyStatus: string | null
    destinationDutyStatus: string | null
    company: string | null
    originSite: string | null
    destinationSite: string | null
    meanOfTransportation: string | null
    instruction: string | null
    comment: string | null
    originProductId: Guid | null
    destinationProductId: Guid | null
    counterpartyId: Guid | null
    mirrorMovementId: Guid | null
    showMirrorMovement: boolean
    externalReference: string | null
    corePropertiesFrozen: boolean
    readOnly: boolean

    movementType: MovementType | null
    movementStatus: MovementStatus

    transporterId: Guid | null
    sapPaymentTerms: string | null
    sapMeanOfTransportation: string | null

    observedQuantity: number | null
    observedTemperature: number | null
    associatedDealId: Guid | null
    associatedTruckId: Guid | null
    associatedVesselId: Guid | null
    originExternalNumber: string | null
    destinationExternalNumber: string | null

    nomination: boolean

    mainSapFlow: SapFlow
    secondSapFlow: SapFlow | null
    operationStartAt: StringDate | null
    operationEndAt: StringDate | null
}

export type SapFlow = {
    id: Guid
    movementType: MovementType | null

    materialDocEntryDate: string | null
    referenceOrder: string | null
    materialDocument: string | null
    deliveryNote: string | null
    sapFlowType: SapFlowType

    originPlant: string | null
    destinationPlant: string | null
    originStorageLocation: string | null
    destinationStorageLocation: string | null

    releaseIndicator: ReleaseIndicator
    goodsIssue: string | null
    vettingNumber: string | null

    useObservedTemperatureInSap: boolean
    postingDate: string | null
}

export type ReleaseIndicator = null | 'NoValidationNeeded' | 'Validated' | 'ToBeValidated'

export type BatchMovement = {
    fromDate: StringDate | null
    toDate: StringDate | null
    every: number | null
    mode: BatchMode
    days: Day[]
    movementTemplate: MovementForm | null
}

export type StockMovementErrors = {
    movementType: MovementType
    date?: boolean
    sameDate?: boolean
    startAfterEnd?: boolean
    volume?: boolean
    dutyStatus?: boolean
    company?: boolean
    site?: boolean
    product?: boolean
    companyDutyStatus?: boolean
}

export type StockProjectionResult = {
    minDays: number | null
    lastCalibratedDate: string | null
    values: StockProjection[] | null
    securityStockLines: ValueDate[] | null
    minVolumes: ValueDate[] | null
    lowVolumes: ValueDate[] | null
    highVolumes: ValueDate[] | null
    maxVolumes: ValueDate[] | null
    lines: ChartStockProjectionLine[] | null
}

export type StockProjectionDetail = {
    date: Date
    dutyStatuss: string
    companys: string
    productCodes: string
    associatedKind: string | null
    values: StockProjectionSiteDifference[]
}

export type StockSimulation = {
    date: string,
    value: number,
    movementType: string
}

export type StockProjectionSiteDifference = {
    site: string
    siteName: string
    actual?: number
    actualStockStatus?: ActualStockStatus
    projected?: number
    difference?: number
    untriggeredQuantity: number
    globalCapacity?: number
    associated?: number
    siteCapacity: SiteCapacity
}

export type TransitStockDetail = {
    date: Date
    destinationSites: string
    dutyStatuss: string
    companys: string
    productCodes: string
    values: TransitStockDetailSite[]
}

export type TransitStockDetailSite = {
    originSite: string
    forecastQuantity: number
    plannedQuantity: number
    actualQuantity: number
}

export type ChartStockProjectionLine = {
    name: string | null
    colorCode: string | null
    values: ChartStockProjection[]
    simulatedValues: ChartStockProjection[]
    withResellerRestriction: boolean
}

export type ValueDate = {
    date: StringDate | null
    value: number | null
}

export type ChartStockProjection = {
    date: StringDate | null
    projectedStock: number | null
    daysLeft: number | null
    volumeInTransit: number | null
}

export type StockProjection = {
    date: StringDate
    actualStock: number | null
    projectedStock: number | null
    daysLeft: number | null
    simulatedStock: number | null
    simulatedDaysLeft: number | null
    highPercent: number | null
    maxPercent: number | null
    volumeIn: number | null
    volumeOut: number | null
    volumeOther: number | null
    volumeTypes: { type: string, volume: number | null }[]
    volumeInTransit: number | null
    actualStockStatus: ActualStockStatus
    isProjectedPartial: boolean | null
}

export enum StockProjectionMode { Sum, Each }

export type MovementListItem = {
    id: Guid
    movementType: MovementType | null
    reference: string | null
    date: StringDate | null
    dutyStatus: string | null
    actualVolume: number | null
    text: string | null
    subText: string | null
    volume: number | null
    movementStatus: MovementStatus | null
    mainSapFlowListItem: {
        steps: SapFlowStepStatus[]
        originPlant: string | null
        originStorageLocation: string | null
        destinationPlant: string | null
        destinationStorageLocation: string | null
    }
    secondSapFlowListItem?: { steps: SapFlowStepStatus[] }
    mainSapFlowId: string | null
    secondSapFlowId: string | null
    meanOfTransportation: string | null
    associatedDealId: Guid
    nomination: boolean
    associatedTruckId: Guid
    associatedVesselId: Guid
    observedQuantity: number | null
    observedTemperature: number | null
    originExternalNumber: string | null
    destinationExternalNumber: string | null
    nominatedVolume: number | null
    dealReference: string | null
    dealNumber: string | null
    dealSapVendor: string | null
    dealSapShipTo: string | null
    vesselReference: string | null
    truckReference: string | null
    material: string | null
}

export type SapFlowStepName = 'STO' | 'PO' | 'GR' | 'SO' | 'DN' | 'GI' | 'GT' | 'MD' | 'RE'

export type SapFlowStepStatus = {
    name: SapFlowStepName
    value: string | null
    isCompleted: boolean
}

export type DutyStatus = string

export type StockProduct = {
    id: Guid
    code: string
    unit: string
    productGroup: string | null
    conversionFactor: number
    defaultQuotationCode: string
}

export type StockInput = {
    stockId: Guid | null
    version: string | null
    date: StringDate | null
    site: string | null
    isCalibration: boolean
    accountingPeriodStatus: AccountingPeriodStatus
    values: StockInputValue[]
    projectedQuantitys: ProjectedQuantity[]
    siteCapacitys: SiteCapacity[]
    globalSiteCapacitys: GlobalSiteCapacity[]
    calibratedAt: StringDate | null
    calibratedBy: string | null
}

export type StockInputValue = {
    productId: Guid
    productCode: string
    company: string
    companyName: string
    volume?: number,
    isCalibration: boolean
    allocatedQuantity?: number
    unusableQuantity?: number
    openingQuantity?: number
    dutyStatus: string
    volumeOverwritten?: boolean
}

export type SiteCapacity = {
    productId: Guid
    company: string
    minVolume: number | null
    lowVolume: number | null
    highVolume: number | null
    maxVolume: number | null
    percentageByHigh: number | null
    percentageByMax: number | null
}

export type ProjectedQuantity = {
    productId: Guid
    company: string
    quantity: number | null
    isOpening: boolean
}

export type GlobalSiteCapacity = {
    productId: Guid
    value: number | null
}

export type SiteCompanyProduct = {
    company: string
    product: string
    dutyStatus: string
}

export type Counterparty = {
    id: Guid
    name: string
}

export type Site = {
    code: string
    name: string
    siteGroup: string
    allocatedQuantity: string | null
    unusableQuantity: string | null
}

export type Company = {
    code: string
    name: string
    country: string
    dutyStatuses: DutyStatus[]
}

export type MessageMovement = {
    id: Guid
    startDate: StringDate | null
    endDate: StringDate | null
    country: string
    type: string
    status: string | null
    timestamp: StringDate | null
    reference: string | null
    reference2: string | null
    reference3: string | null
    flowRate: string | null
    reference4: string | null
    part: number | null
    volume: number | null
    unit: string | null
    direction: string | null
    productCode: Guid | null
    counterparty: string | null
    site: string | null
    siteCode: string | null
    instruction: string | null
    externalReference: string | null
    hasMovementWithSameReference: boolean | null,
    operationStartAt: null,
    operationEndAt: null
}

export enum BatchMode {
    Day = 0, Week = 1
}

export enum Day {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6,
}

export enum MovementType {
    Sale = 0,
    Purchase = 1,
    Transfer = 2,
    Rebranding = 3,
    Borrow = 4,
    Loan = 5,
    StatusChange = 6,
    Gains = 701,
    Losses = 702,
    Untriggered = 1002,
    Unknown = 99,
    MktSale = 10
}

export type ActualStockStatus = NotCalibrated | Mixed | FullyCalibrated | Opened | Closed | Frozen
export type AccountingPeriodStatus = Opened | Closed | Frozen | null
type NotCalibrated = 'NotCalibrated'
type Mixed = 'Mixed'
type FullyCalibrated = 'FullyCalibrated'
type Opened = 'Opened'
type Closed = 'Closed'
type Frozen = 'Frozen'

export function MovementTypeNameFromMovementType(type: MovementType | undefined | null): string {
    switch (type) {
        case MovementType.Sale: return t('stock.label.sale')
        case MovementType.Purchase: return t('stock.label.purchase')
        case MovementType.Transfer: return t('stock.label.transfer')
        case MovementType.Rebranding: return t('stock.label.rebranding')
        case MovementType.Borrow: return t('stock.label.borrow')
        case MovementType.Loan: return t('stock.label.loan')
        case MovementType.StatusChange: return t('stock.label.statusChange')
        case MovementType.Gains: return t('stock.label.gains')
        case MovementType.Losses: return t('stock.label.losses')
        case MovementType.Untriggered: return t('stock.label.untriggered')
        case MovementType.MktSale: return t('stock.label.mktSales')
        default: return t('stock.label.unknown')
    }
}

export function MovementTypeFromMovementTypeName(type: string): MovementType | null {
    switch (type) {
        case t('stock.label.sale'): return MovementType.Sale
        case t('stock.label.purchase'): return MovementType.Purchase
        case t('stock.label.untriggered'): return MovementType.Untriggered
        case t('stock.label.transfer'): return MovementType.Transfer
        case t('stock.label.rebranding'): return MovementType.Rebranding
        case t('stock.label.borrow'): return MovementType.Borrow
        case t('stock.label.loan'): return MovementType.Loan
        case t('stock.label.statusChange'): return MovementType.StatusChange
        case t('stock.label.gains'): return MovementType.Gains
        case t('stock.label.losses'): return MovementType.Losses
        case t('stock.label.mktSales'): return MovementType.MktSale
        default: return null
    }
}

export function MeanOfTransportationType(type: string): string | null {
    switch (type) {
        case "InTank":
        case "Pipe":
        case "Road":
        case "Ship":
        case "Train":
        case "X Pump":
        case "Barge":
            return type
        default: return null
    }
}

export function MovementStatusNameFromMovementStatus(status: MovementStatus | undefined | null): string {
    switch (status) {
        case MovementStatus.Forecast: return t('vessels.label.status.forecast')
        case MovementStatus.Planned: return t('vessels.label.status.planned')
        case MovementStatus.Actual: return t('vessels.label.status.actual')
        default: return t('stock.label.unknown')
    }
}

export function MovementStatusFromMovementStatusName(status: string): MovementStatus | null {
    switch (status) {
        case 'Forecast': return MovementStatus.Forecast
        case 'Planned': return MovementStatus.Planned
        case 'Actual': return MovementStatus.Actual
        default: return null
    }
}

export enum MovementStatus {
    Forecast = 0,
    Planned = 1,
    Actual = 2,
    Unknown = 99
}

export type MeanOfTransportation = string

export type StockFilters = {
    companies: string[] | null
    dutyStatuses: string[] | null
    sites: string[] | null
    types: string[]
    start: string | null
    end: string | null
    monthRange: number
    productIds: Guid[] | null
    resellersRestrictionPercentage: number | null
    resellersRestrictionStart: string | null
    resellersRestrictionEnd: string | null
    stockPercentHigh: boolean
    usefulStock: boolean
    allSites: string[] | null
    allProductIds: Guid[] | null
    allDutyStatuses: string[] | null
    allCompanies: string[] | null
}

export type PlantForm = {
    code: string
    name: string
    storageLocations: StorageLocationForm[]
}

export type StorageLocationForm = {
    code: string
    name: string
}

export type PlantParams = {
    site: string
    productId: string
    dutyStatus: string
    companyCode: string
}

export let defaultStockInput: StockInput = {
    date: moment.utc(`${moment().year()}${('000' + (moment().month() + 1)).slice(-2)}${('000' + (moment().date())).slice(-2)}`, 'YYYYMMDD').format(),
    site: null,
    stockId: null,
    version: null,
    isCalibration: false,
    accountingPeriodStatus: null,
    values: [],
    projectedQuantitys: [],
    siteCapacitys: [],
    globalSiteCapacitys: [],
    calibratedAt: null,
    calibratedBy: null
}

export let defaultStockMovement = (): MovementForm => ({
    id: '',
    movementType: null,
    originDutyStatus: null,
    destinationDutyStatus: null,
    stockInputDate: null,
    stockOutputDate: null,
    volume: null,
    nominatedVolume: null,
    nomination: false,
    reference: null,
    movementStatus: 0,
    company: null,
    originSite: null,
    destinationSite: null,
    meanOfTransportation: null,
    originProductId: null,
    destinationProductId: null,
    counterpartyId: null,
    comment: null,
    instruction: null,
    requestedDate: null,
    mirrorMovementId: null,
    showMirrorMovement: false,
    externalReference: null,
    corePropertiesFrozen: false,
    readOnly: false,
    transporterId: null,
    sapPaymentTerms: null,
    sapMeanOfTransportation: null,
    observedQuantity: null,
    observedTemperature: null,
    associatedDealId: null,
    associatedTruckId: null,
    associatedVesselId: null,
    originExternalNumber: null,
    destinationExternalNumber: null,
    mainSapFlow: {
        id: '',
        movementType: null,
        sapFlowType: null,
        referenceOrder: null,
        deliveryNote: null,
        materialDocument: null,
        materialDocEntryDate: null,
        originPlant: null,
        originStorageLocation: null,
        destinationPlant: null,
        destinationStorageLocation: null,
        releaseIndicator: null,
        goodsIssue: null,
        vettingNumber: null,
        useObservedTemperatureInSap: hasFeature('UseObservedTemperatureByDefault'),
        postingDate: null,
    },
    secondSapFlow: null,
    operationStartAt: null,
    operationEndAt: null
})

export type DuplicateMovementCommand = {
    sourceId: Guid
    duplicateId: Guid
}

export type TripDurationDto = {
    value: number | null
}

export type PricingType = { name: string }

type DefaultFlowType = null
export type TransferFlowType = 'sto' | 'direct301'
export type SapFlowType = DefaultFlowType | TransferFlowType

export let biDirectionalsMovementTypes = [MovementType.Transfer, MovementType.StatusChange, MovementType.Rebranding]
export let inMovementTypes = [MovementType.Purchase, MovementType.Borrow, MovementType.Gains].concat(biDirectionalsMovementTypes)
export let outMovementTypes = [MovementType.Sale, MovementType.Loan, MovementType.Losses].concat(biDirectionalsMovementTypes)

export let defaultAssociatedDeal = (): DealLabelId => ({
    id: guid.empty,
    reference: null,
    validFrom: null,
    validTo: null,
    dutyStatus: null,
    site: null
})

export type MovementTypeSelectionOptions = {
    showPurchase: boolean
    showSale: boolean
    showTransfer: boolean
    showStatusChange: boolean
    showRebranding: boolean
    showBorrow: boolean
    showGain: boolean
    showLoan: boolean
    showLosses: boolean
    showInOutSwitch: boolean
    showUniqueRecurrentSwitch: boolean
}

export let defaultMovementTypeSelectionOptions: MovementTypeSelectionOptions = {
    showPurchase: true,
    showSale: true,
    showTransfer: true,
    showStatusChange: true,
    showRebranding: true,
    showBorrow: true,
    showGain: true,
    showLoan: true,
    showLosses: true,
    showInOutSwitch: true,
    showUniqueRecurrentSwitch: true
}

export type MessageMovementFilters = {
    sites?: string[] | null
    productIds?: Guid[] | null
    type: string | null
}

export enum MessageMovementType {
    EDI = 'EDI',
    OpsNotice = 'OpsNotice'
} 