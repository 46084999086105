import React, { useMemo, useRef, useState } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { t } from '../../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps, defaultStyles } from '../../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../../infrastructure/feature'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { switcherStyle } from './_commonStyle'
import { api } from '../../../../infrastructure/api'
import { queryStringBuilder } from '../../../../infrastructure/queryStringBuilder'
import moment from 'moment'
import { MovementForm, TripDurationDto } from '../../stockModels'
import { FieldHintEffect } from '../../../common/customComponents'
import { FieldHintEffectRef } from '../../../common/components/fieldHintEffect'

function _transferForm({ isIn, classes }: { isIn: boolean } & MuiProps) {
    let store = StockMovementContainer.useContainer()

    const fieldHintEffectRef = useRef<FieldHintEffectRef>(null);

    let onlyOneDutyStatus: boolean = !hasFeature("MultipleTransferDutyStatus")
        || !store.stockMovement.originDutyStatus
        || !store.stockMovement.destinationDutyStatus
        || store.stockMovement.originDutyStatus == store.stockMovement.destinationDutyStatus

    let onlyOneDate: boolean = (!hasFeature("TransitVolume")
        || !store.stockMovement.stockInputDate
        || !store.stockMovement.stockOutputDate)
        && store.stockMovement.stockOutputDate === store.stockMovement.stockInputDate

    let applyTripDurationToDate = async (movement: MovementForm) => {
        let params = {
            company: movement.company,
            originSite: movement.originSite,
            destinationSite: movement.destinationSite,
            productId: movement.originProductId ?? movement.destinationProductId,
            dutyStatus: movement.originDutyStatus ?? movement.destinationDutyStatus,
            meanOfTransportation: movement.meanOfTransportation
        }
        let query = queryStringBuilder(params)
        let tripDuration = await api.get<TripDurationDto>(`stock/movement/searchTripDuration${query}`)

        return tripDuration.value !== null
            ? moment(movement.stockOutputDate).add(tripDuration.value!, 'day').toISOString()
            : movement.stockInputDate
    }

    let tripDurationDependingFieldsAreFilled = (movement: MovementForm) =>
        (movement.meanOfTransportation === "Road" || movement.meanOfTransportation === "Train")
        && movement.company && movement.stockOutputDate && movement.originSite && movement.destinationSite &&
        (movement.originProductId || movement.destinationProductId) &&
        (movement.originDutyStatus || movement.destinationDutyStatus)

    let tripDurationDependingFieldsHasChanged = (movement: MovementForm) => {
        return store.stockMovement.meanOfTransportation !== movement.meanOfTransportation ||
            store.stockMovement.stockOutputDate !== movement.stockOutputDate ||
            store.stockMovement.company !== movement.company ||
            store.stockMovement.originSite !== movement.originSite ||
            store.stockMovement.destinationSite !== movement.destinationSite ||
            store.stockMovement.originDutyStatus !== movement.originDutyStatus ||
            store.stockMovement.originProductId !== movement.originProductId ||
            store.stockMovement.destinationProductId !== movement.destinationProductId ||
            store.stockMovement.destinationDutyStatus !== movement.destinationDutyStatus
    }

    let changeStockMovement = async (movement: MovementForm) => {
        if (tripDurationDependingFieldsAreFilled(movement) && tripDurationDependingFieldsHasChanged(movement)) {
            let newDate = await applyTripDurationToDate(movement)

            if (movement.stockInputDate !== newDate)
                fieldHintEffectRef.current?.animate()

            store.setStockMovement({ ...movement, stockInputDate: newDate })
        } else
            store.setStockMovement(movement)
    }

    return (
        <div>
            <div className={classes.row + ' transferRow'}>
                <Component.ModeSwitcher
                    initiallyDualMode={!onlyOneDate}
                    forceDualMode={hasFeature('TransferMultipleDates')}
                    canSwitch={true}
                    originComponent={isIn
                        ? <FieldHintEffect fieldHintEffectRef={fieldHintEffectRef}>
                            <Component.InputDate onChangeOverride={changeStockMovement} />
                        </FieldHintEffect>
                        : <Component.OutputDate onChangeOverride={changeStockMovement} />}
                    destinationComponent={isIn
                        ? <Component.OutputDate onChangeOverride={changeStockMovement} />
                        : <FieldHintEffect fieldHintEffectRef={fieldHintEffectRef}>
                            <Component.InputDate onChangeOverride={changeStockMovement} />
                        </FieldHintEffect>}
                    singleModeComponent={<Component.Date isIn={isIn} />}
                    onResetDualMode={() => changeStockMovement({ ...store.stockMovement, stockInputDate: null })}
                    tooltipText={t('stock.label.movement.changeDatesSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.dateAdornment}
                    hideArrow={store.batchMovement != null} />
                <Component.Product onChangeOverride={changeStockMovement} isIn={isIn} />
                <Component.Company onChangeOverride={changeStockMovement} />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.ModeSwitcher
                    initiallyDualMode={!onlyOneDutyStatus}
                    canSwitch={hasFeature("MultipleTransferDutyStatus")}
                    originComponent={isIn
                        ? <Component.OriginDutyStatus onChangeOverride={changeStockMovement} />
                        : <Component.DestinationDutyStatus onChangeOverride={changeStockMovement} />}
                    destinationComponent={isIn
                        ? <Component.DestinationDutyStatus onChangeOverride={changeStockMovement} />
                        : <Component.OriginDutyStatus onChangeOverride={changeStockMovement} />}
                    singleModeComponent={<Component.DutyStatus isIn={isIn} />}
                    onResetDualMode={() => changeStockMovement({ ...store.stockMovement, destinationDutyStatus: null })}
                    tooltipText={t('stock.label.movement.changeDutyStatusSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Mot onChangeOverride={changeStockMovement} />
                <Component.Status />
                {hasFeature('ShowPurchaseNominatedVolume') || (hasFeature('DailyNomination') || hasFeature('WeeklyNomination'))
                    ? <div className={classes.row}>
                        {hasFeature('ShowPurchaseRequestedDate') && <Component.RequestedDate />}
                        {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination') || hasFeature('ShowPurchaseNominatedVolume')) &&
                            <div className={classes.alignedToVolume}>
                                <Component.NominatedVolume />
                            </div>}
                        {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination')) && <Component.Nomination />}
                    </div>
                    : undefined}
            </div>
            {hasFeature('MessageMovements') && store.stockMovement.meanOfTransportation === 'Pipe' &&
                <div>
                    <Component.OperationStartAt />
                    <Component.OperationEndAt />
                </div>
            }
            <div className={classes.row + ' transferRow'}>
                <Component.ModeSwitcher
                    initiallyDualMode={true}
                    canSwitch={false}
                    forceDualMode={true}
                    originComponent={<Component.OriginSite onChangeOverride={changeStockMovement} />}
                    destinationComponent={<Component.DestinationSite onChangeOverride={changeStockMovement} />}
                    singleModeComponent={<></>}
                    onResetDualMode={() => { }}
                    tooltipText={''}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') &&
                    <>
                        <Component.OriginExternalNumber />
                        <Component.DestinationExternalNumber />
                    </>}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.column}>
                <Component.Instruction />
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        ...switcherStyle,
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        column: {
            ...defaultStyles.flexColumn,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em',
            "& > div": {
                marginTop: "0.5em",
                marginBottom: "0.5em"
            }
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: '0em, 1em'
        },
    })

export default withStyles(styles, muiOptions)(_transferForm)