import { Dialog, DialogTitle, DialogContent, DialogActions, createStyles, withStyles } from "@material-ui/core"
import React, { useState } from 'react'
import { MuiProps, muiOptions } from "../../infrastructure/materialUiThemeProvider"
import { MktSalesComment } from "./models"
import { convertToRaw, convertFromRaw } from 'draft-js'
import { Button } from "../common/customComponents"
import MUIRichTextEditor from "mui-rte"
import { t } from '../../infrastructure/i18nextHelper'

type MktSalesCommentProps = {
    isOpen: boolean,
    entity?: MktSalesComment | null,
    onSave: (arg: MktSalesComment) => void
    onClose: () => void
}

function _MktSalesCommentDialog({ ...props }: MktSalesCommentProps & MuiProps) {
    let [content, setContent] = useState<Draft.EditorState | null>(null)

    let onSave = () => {
        if (!content) return

        let currentContent = JSON.stringify(convertToRaw(content.getCurrentContent()))
        let comment = isContentEmpty(currentContent) ? null : currentContent

        props.onSave({
            id: props.entity!.id,
            comment: comment
        })

        props.onClose()
    }

    let isContentEmpty = (content: string | undefined) => {
        if (content) {
            let parsedComment = convertFromRaw(JSON.parse(content))
            let plainTxt = parsedComment.getPlainText()
            return !plainTxt
        }
        return true
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle className={props.classes.dialogContainer}>
                <div className={props.classes.popupHeader}> {t('comment.dialogTitle')} </div>
            </DialogTitle>
            <DialogContent className={props.classes.dialogContentContainer} dividers={true}>
                <div className={props.classes.contentContainer}>
                    <MUIRichTextEditor
                        defaultValue={props.entity?.comment}
                        onChange={e => setContent(e)}
                        inheritFontSize={true}
                        label={t('comment.placeHolder')}
                        controls={[]} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('comment.closeButton')}
                    onClick={props.onClose}
                    color='primary' />
                <Button className={props.classes.saveButton}
                    label={t('comment.saveButton')}
                    onClick={onSave}
                    color='primary' />
            </DialogActions>
        </Dialog>)
}

let styles = () =>
    createStyles({
        dialogContentContainer: {
            paddingTop: '0px',
            paddingBottom: '10px'
        },
        contentContainer: { minHeight: '10em', width: '45em' }
    })

export let MktSalesCommentDialog = withStyles(styles, muiOptions)(_MktSalesCommentDialog)