import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

export function useTimePickerState(time, setTime, onError) {
    let [isOnError, setIsOnError] = React.useState<boolean>(!!onError);

    function onChange(newTime: moment.Moment | null, newText: string | null) {
        let newState = {
            time: null as string | null,
            isOnError: false
        };

        if (newTime !== null) {
            if (!newTime.isValid()) {
                newState.isOnError = true;
            } else {
                newState.time = newTime.toISOString();
            }
        }

        ReactDOM.unstable_batchedUpdates(() => {
            setTime(!newText ? null : newState.time ?? time);
            setIsOnError(newState.isOnError);
        });
    }

    return { isOnError, setIsOnError, onChange };
}
